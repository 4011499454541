import React, { useEffect, useState } from 'react'
import Alert from '@sport1/news-styleguide/Alert'
import Button from '@sport1/news-styleguide/Button'
import Font from '@sport1/news-styleguide/Font'

const AlertBar = () => {
    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {
        const alertDismissed = localStorage.getItem('alertDismissed')
        if (!alertDismissed) {
            setShowAlert(true)
        }
    }, [])

    const handleDismiss = () => {
        localStorage.setItem('alertDismissed', 'true')
        setShowAlert(false)
    }

    if (!showAlert) {
        return null
    }

    return (
        <Alert
            backgroundColor="yellow"
            buttonSpaceBetween="small"
            text={
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                    }}
                >
                    <Font
                        fontSize="font-size-6"
                        fontFamilyVariant="Sport1-Bold"
                        marginBottom="spacing-4"
                    >
                        Wartungsarbeiten
                    </Font>
                    <Font textAlign="center">
                        Liebe Nutzerinnen und Nutzer, aktuell führen wir Wartungsarbeiten auf
                        sport1.de durch, um unser Angebot für Sie weiter zu verbessern. Die Seite
                        wird währenddessen nicht aktualisiert. Wir bitten um Ihr Verständnis und
                        entschuldigen uns für die Unannehmlichkeiten.
                    </Font>
                    <Font
                        fontSize="font-size-6"
                        fontFamilyVariant="Sport1-Bold"
                        marginTop="spacing-4"
                    >
                        Ihr SPORT1-Team
                    </Font>
                </div>
            }
            primaryButton={
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                    }}
                >
                    <Button
                        text="HINWEIS SCHLIESSEN"
                        textAlign="center"
                        expand
                        onPress={handleDismiss}
                    />
                </div>
            }
        />
    )
}

export { AlertBar }
